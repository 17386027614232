import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from 'antd';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { cancellationPolicy, policyRefundTextStyle } from './index.module.scss';

function CancellationPolicy({
  totalPrice, reservationFee, checkinDate, deadlineDay, nonRefundable,
  policyRefundText,
}) {
  const intl = useIntl();
  const freeRefundDate = checkinDate.clone();
  const freeRefundLimit = checkinDate.clone();
  const actualDate = moment();
  freeRefundDate.subtract(deadlineDay + 1, 'days');
  freeRefundLimit.subtract(deadlineDay, 'days');
  const depositAmount = totalPrice * reservationFee;
  const isOutOfRefund = freeRefundDate.isBefore(actualDate);

  return (
    <div className={cancellationPolicy}>
      <Typography.Title level={2}>
        {intl.formatMessage({ id: 'CancellationPolicy.Title' })}
      </Typography.Title>
      {nonRefundable && (
        <>
          <strong>
            {intl.formatMessage({ id: 'CancellationPolicy.NoRefundable' })}
          </strong>
          <p>
            {intl.formatMessage({ id: 'CancellationPolicy.NoRefundable.Text' })}
          </p>
        </>
      )}
      {!isOutOfRefund && !nonRefundable && (
        <>
          <strong className={policyRefundTextStyle}>
            {policyRefundText}
          </strong>
          <p>
            {intl.formatMessage({ id: 'CancellationPolicy.DepositRefund' }, { depositAmount })}
          </p>
        </>
      )}
      {!nonRefundable && (
      <>
        <strong>
          {intl.formatMessage({ id: 'CancellationPolicy.NotShowingUp' })}
        </strong>
        <p>
          {intl.formatMessage({ id: 'CancellationPolicy.NotShowingUpPenalty' }, { totalPrice })}
        </p>
      </>
      )}
    </div>
  );
}

CancellationPolicy.propTypes = {
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  reservationFee: PropTypes.number.isRequired,
  checkinDate: PropTypes.oneOfType([PropTypes.object]).isRequired,
  deadlineDay: PropTypes.number.isRequired,
  nonRefundable: PropTypes.bool.isRequired,
  policyRefundText: PropTypes.string.isRequired,
};

export default CancellationPolicy;
