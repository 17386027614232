/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { Popover } from "antd";
import { PropTypes } from "prop-types";
import moment from "moment";
import { FormattedNumber, useIntl, FormattedMessage } from "react-intl";
import TribeTag from "../TribeTag";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-filled.svg";
import {
  wrapper,
  rateColumns,
  ratePensionColumns,
  container,
  notSelected,
  inner,
  price,
  tooltip,
  infoIcon,
  flexColumns,
  rateName,
  priceDiscounted,
  popover,
  notRateAvailable,
  giftWrapper,
  tribuPrice,
  line,
} from "./index.module.scss";
import CancellationPolicy from "../CancellationPolicy";
import useCampings from "../../services/Campings/useCampings";
import { useAuthState } from "../../services/Auth/useAuth";
import PromotionBanner from "../PromotionBanner";

const RatesSelector = ({
  filteredRatePlans,
  setSelectedRate,
  propertyID,
  setIsFirstLoad,
}) => {
  const [pensionType, setPensionType] = useState(filteredRatePlans[0]?.code);
  const [rateType, setRateType] = useState();
  const [selectedRateDetails, setSelectedRateDetails] = useState([]);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const checkinFromURL = searchParams.get("checkin");
  const {
    state: {
      campings: { [propertyID]: camping },
    },
  } = useCampings();
  const { accessToken } = useAuthState();
  const property = camping;
  const reservationFee = property?.fee;
  const intl = useIntl();
  const [activeBoard, setActiveBoard] = useState(false);

  useEffect(() => {
    const ratePlanSelected = filteredRatePlans.find(
      (plan) => plan?.code === pensionType,
    );
    if (ratePlanSelected) {
      const details = ratePlanSelected.plans.map((rate) => ({
        boardId: ratePlanSelected.id,
        rateName: rate.nameI18n ? rate.nameI18n : rate.rateName,
        description: rate.propertyPolicy?.description,
        rateId: rate.rateId,
        totalPrice: rate.totalPrice,
        nonRefundable: rate?.propertyPolicy?.nonRefundable,
        deadlineDay: rate?.propertyPolicy?.deadlineDay,
        offer: rate?.offer,
        promotion: rate?.promotion,
        roomRateDetailed: rate?.roomRateDetailed,
        propertyPolicy: rate?.propertyPolicy,
      }));
      setSelectedRateDetails(details);
    } else {
      setSelectedRateDetails([]);
    }
  }, [filteredRatePlans, pensionType]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rateFromURL = parseInt(params.get("rate"), 10);
    const boardFromURL = parseInt(params.get("board"), 10);
    const offerFromURL = parseInt(params.get("offer"), 10);
    const promotionFromURL = parseInt(params.get("promotion"), 10);
    let lowestPriceIndex = -1;
    let lowestPrice = Infinity;

    if (rateFromURL) {
      let matchingPlan = null;
      let matchingPlanIndex = -1;
      let boardCode = null;
      let matchesBoardCondition = false;
      let boardId = null;

      filteredRatePlans.find((plan) => {
        const matchesBoard = !boardFromURL || plan.id === boardFromURL;
        matchesBoardCondition = matchesBoard;

        if (matchesBoard) {
          const foundIndex = plan.plans.findIndex((element) => {
            const matchesRate = !rateFromURL || element?.rateId === rateFromURL;
            let matchesOffer;
            if (!offerFromURL) {
              matchesOffer = true;
            } else {
              matchesOffer = element?.offer?.id === offerFromURL;
            }

            let matchesPromotion;
            if (!promotionFromURL) {
              matchesPromotion = true;
            } else {
              matchesPromotion = element?.promotion?.id === promotionFromURL;
            }

            return (
              matchesRate && matchesBoard && matchesOffer && matchesPromotion
            );
            // Plan found and match with URL params
          });

          if (foundIndex !== -1) {
            matchingPlan = plan.plans[foundIndex];
            matchingPlanIndex = foundIndex;
            boardCode = plan?.code;
            boardId = plan?.id;
            return true;
          }
        }
        return false;
      });

      const extendedMatchingRate = {
        ...matchingPlan,
        matchesBoard: matchesBoardCondition,
        boardId,
      };
      if (matchingPlan && !activeBoard) {
        setRateType(matchingPlanIndex);
        setSelectedRate(extendedMatchingRate);
        setPensionType(boardCode);
      } else {
        selectedRateDetails.forEach((rateDetail, index) => {
          if (
            typeof rateDetail.totalPrice === "number" &&
            rateDetail.totalPrice < lowestPrice
          ) {
            lowestPrice = rateDetail.totalPrice;
            lowestPriceIndex = index;
          }
        });

        if (lowestPriceIndex !== -1) {
          setRateType(lowestPriceIndex);
          setSelectedRate(selectedRateDetails[lowestPriceIndex]);
        } else {
          setRateType(null);
          setSelectedRate(null);
        }
      }
    } else {
      // URL params are incorect or don´t exist.
      // Both cases we found the cheapest plan.
      selectedRateDetails.forEach((rateDetail, index) => {
        if (
          typeof rateDetail.totalPrice === "number" &&
          rateDetail.totalPrice < lowestPrice
        ) {
          lowestPrice = rateDetail.totalPrice;
          lowestPriceIndex = index;
        }
      });

      if (lowestPriceIndex !== -1) {
        setRateType(lowestPriceIndex);
        setSelectedRate(selectedRateDetails[lowestPriceIndex]);
      } else {
        setRateType(null);
        setSelectedRate(null);
      }
    }
  }, [selectedRateDetails, filteredRatePlans, activeBoard, setSelectedRate]);

  const calculateCombinedDiscount = (offer, promotion) => {
    // Verify if both, offer and promotion has dscount per %.
    if (offer?.percentDiscount > 0 && promotion?.percentDiscount > 0) {
      const combinedDiscount = Math.floor(
        offer.percentDiscount +
          promotion.percentDiscount -
          (offer.percentDiscount * promotion.percentDiscount) / 100,
      );
      return {
        value: combinedDiscount,
        type: "%",
      };
    }
    if (offer?.amountDiscount > 0 && promotion?.amountDiscount > 0) {
      const combinedDiscount = Math.floor(
        offer.amountDiscount + promotion.amountDiscount,
      );
      return {
        value: combinedDiscount,
        type: "€",
      };
    }
    if (
      (offer?.percentDiscount > 0 && promotion?.amountDiscount > 0) ||
      (offer?.amountDiscount > 0 && promotion?.percentDiscount > 0)
    ) {
      // one percernt and another per amount
      const discounts = [];
      if (offer.amountDiscount > 0)
        discounts.push({ value: offer.amountDiscount, type: "€" });
      if (offer.percentDiscount > 0)
        discounts.push({ value: offer.percentDiscount, type: "%" });
      if (
        promotion.amountDiscount > 0 &&
        !discounts.some((discount) => discount.type === "€")
      )
        discounts.push({ value: promotion.amountDiscount, type: "€" });
      if (
        promotion.percentDiscount > 0 &&
        !discounts.some((discount) => discount.type === "%")
      )
        discounts.push({ value: promotion.percentDiscount, type: "%" });
      return discounts;
    }
    return null;
  };

  const formatOfferAndPromotionText = (rate) => {
    const elements = [];
    let includeOfferDiscount = true;
    let includePromotionDiscount = true;

    const combinedDiscountResult =
      rate?.offer && rate?.promotion
        ? calculateCombinedDiscount(rate?.offer, rate?.promotion)
        : null;

    if (combinedDiscountResult != null) {
      includeOfferDiscount = false;
      includePromotionDiscount = false;
    }

    const conditions = [
      { key: "offer", value: rate?.offer },
      { key: "promotion", value: rate?.promotion },
    ];

    conditions.forEach(({ key, value }) => {
      if (value && value) {
        elements.push(
          <p key={key}>
            {" "}
            {!value?.tagList?.length > 0 && value?.nameI18n}{" "}
            {key === "offer" &&
              includeOfferDiscount &&
              value?.amountDiscount > 0 &&
              !value?.tagList?.length > 0 &&
              `-${value?.amountDiscount}€`}
            {key === "offer" &&
              includeOfferDiscount &&
              value?.percentDiscount > 0 &&
              !value?.tagList?.length > 0 &&
              `-${value?.percentDiscount}%`}
            {key === "promotion" &&
              includePromotionDiscount &&
              !value?.tagList?.length > 0 &&
              value?.amountDiscount > 0 &&
              `-${value?.amountDiscount}€`}
            {key === "promotion" &&
              includePromotionDiscount &&
              !value?.tagList?.length > 0 &&
              value?.percentDiscount > 0 &&
              `-${value?.percentDiscount}%`}
          </p>,
        );
      }
    });

    if (combinedDiscountResult != null) {
      if (Array.isArray(combinedDiscountResult)) {
        combinedDiscountResult.forEach((discount) => {
          elements.push(
            <p>
              -{discount.value}
              {discount.type}
            </p>,
          );
        });
      } else {
        elements.push(
          <p>
            -{combinedDiscountResult.value}
            {combinedDiscountResult.type}
          </p>,
        );
      }
    }

    return elements;
  };

  const formatTribeTagList = (rate) => {
    if (!rate?.promotion?.tagList) return null;

    return rate?.promotion?.tagList?.map((tag) => (
      <TribeTag key={tag?.name} tagName={tag?.name} />
    ));
  };

  const formatCancellationPolicy = (rate) => {
    const deadlineCalc = moment(checkinFromURL).subtract(
      rate.deadlineDay,
      "days",
    );
    const today = moment();

    if (deadlineCalc.isBefore(today) || rate?.nonRefundable) {
      return intl.formatMessage({ id: "RatesSelector.NoRefundText" });
    }
    return (
      <FormattedMessage
        id="RatesSelector.FreeRefundDate"
        values={{
          freeRefundDate: moment(checkinFromURL)
            .subtract(rate.deadlineDay, "days")
            .format("dddd, D [de] MMMM"),
          yearDate: moment(checkinFromURL)
            .subtract(rate.deadlineDay, "days")
            .format("YYYY"),
        }}
        tagName="span"
      />
    );
  };

  const handleBoardOnClick = (rate) => {
    if (pensionType === rate?.code) {
      setActiveBoard(false);
    } else {
      setPensionType(rate?.code);
      setActiveBoard(true);
      setIsFirstLoad(false);
    }
  };

  const handlePlanOnClick = (rate, index) => {
    setRateType(index);
    setSelectedRate(rate);
    setIsFirstLoad(false);
  };

  const getDiscountedPrice = (rate) => {
    let discountedPrice = rate?.totalPrice;

    if (rate?.promotion?.percentDiscount > 0) {
      discountedPrice /= 1 - rate?.promotion?.percentDiscount / 100;
    }
    if (rate?.promotion?.amountDiscount > 0) {
      discountedPrice += rate?.promotion?.amountDiscount;
    }

    return discountedPrice;
  };

  return (
    <>
      {filteredRatePlans?.filter((rate) => rate?.plans?.length > 0).length >
        0 && (
        <div className={wrapper}>
          <p>{intl.formatMessage({ id: "RatesSelecto.PensionType" })}</p>
          <div className={container}>
            {filteredRatePlans
              .filter((rate) => rate?.plans?.length > 0)
              .map((rate) => (
                <div className={ratePensionColumns} key={rate?.code}>
                  <button
                    shape="circle"
                    type="button"
                    aria-label="add"
                    className={pensionType === rate?.code ? inner : notSelected}
                    onClick={() => handleBoardOnClick(rate)}
                  />
                  {rate?.name}
                </div>
              ))}
          </div>
        </div>
      )}

      {selectedRateDetails.length > 0 &&
        selectedRateDetails?.some((rate) => rate) && (
          <div className={wrapper}>
            <p>{intl.formatMessage({ id: "RatesSelecto.RateType" })}</p>
            {accessToken === null && (
              <div className={giftWrapper}>
                <PromotionBanner
                  titleKey="PromotionBanner.Room.Text"
                  descriptionKey="PromotionBanner.Room.Description"
                />
              </div>
            )}
            <div className={container}>
              {selectedRateDetails
                .filter((rate) => rate)
                .map((rate, index) => (
                  <div className={rateColumns}>
                    <button
                      shape="circle"
                      type="button"
                      aria-label="add"
                      className={rateType === index ? inner : notSelected}
                      onClick={() => handlePlanOnClick(rate, index)}
                    />
                    <div className={flexColumns}>
                      <div>
                        <span className={rateName}>
                          {rate?.rateName}
                          {formatOfferAndPromotionText(rate)}
                          {formatTribeTagList(rate)}
                        </span>
                        <div>
                          <span>
                            {formatCancellationPolicy(rate, checkinFromURL)}
                          </span>
                          <div className={popover}>
                            <Popover
                              overlayStyle={{
                                paddingBottom: "0px",
                                top: "100px",
                              }}
                              overlayClassName={tooltip}
                              content={
                                <CancellationPolicy
                                  totalPrice={parseFloat(
                                    rate?.totalPrice,
                                  ).toFixed(2)}
                                  reservationFee={reservationFee}
                                  checkinDate={moment(checkinFromURL)}
                                  deadlineDay={rate?.deadlineDay}
                                  nonRefundable={rate?.nonRefundable}
                                  policyRefundText={formatCancellationPolicy(
                                    rate,
                                    checkinFromURL,
                                  )}
                                />
                              }
                              trigger="click"
                            >
                              <InfoIcon className={infoIcon} />
                            </Popover>
                          </div>
                        </div>
                      </div>
                      {rate?.offer || rate?.promotion ? (
                        rate?.promotion?.tagList?.length > 0 ? (
                          <span className={tribuPrice}>
                            {/* eslint-disable react/style-prop-object */}
                            <div className={line}>
                              <FormattedNumber
                                value={getDiscountedPrice(rate)}
                                style="currency"
                                currency="EUR"
                              />
                            </div>
                            <FormattedNumber
                              value={rate?.totalPrice}
                              style="currency"
                              currency="EUR"
                            />
                            {/* eslint-enable react/style-prop-object */}
                          </span>
                        ) : (
                          <span className={priceDiscounted}>
                            {/* eslint-disable react/style-prop-object */}
                            <FormattedNumber
                              value={rate?.totalPrice}
                              style="currency"
                              currency="EUR"
                            />
                            {/* eslint-enable react/style-prop-object */}
                          </span>
                        )
                      ) : (
                        <span className={price}>
                          {/* eslint-disable react/style-prop-object */}
                          <FormattedNumber
                            value={rate?.totalPrice}
                            style="currency"
                            currency="EUR"
                          />
                          {/* eslint-enable react/style-prop-object */}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              {selectedRateDetails.length === 0 ||
              !selectedRateDetails.some((rate) => rate) ? (
                <p className={notRateAvailable}>
                  {intl.formatMessage({ id: "RatesSelector.NoPlanFound" })}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      {/* {selectedRateDetails.length > 0 && selectedRateDetails?.some((rate) => rate) && (
        <div className={wrapper}>
          <p>{intl.formatMessage({ id: 'RatesSelecto.RateType' })}</p>
          <div className={container}>
            {selectedRateDetails
              .filter((rate) => rate)
              .map((rate, index) => (
                <div className={rateColumns}>
                  <button shape="circle" type="button" aria-label="add" className={rateType === index ? inner : notSelected} onClick={() => handlePlanOnClick(rate, index)} />
                  <div className={flexColumns}>
                    <div>
                      <span className={rateName}>
                        {rate?.rateName}
                        {formatOfferAndPromotionText(rate)}
                      </span>
                      <div>
                        <span>{formatCancellationPolicy(rate, checkinFromURL)}</span>
                        <div className={popover}>
                          <Popover
                            overlayStyle={{ paddingBottom: '0px', top: '100px' }}
                            overlayClassName={tooltip}
                            content={(
                              <CancellationPolicy
                                totalPrice={parseFloat(rate?.totalPrice).toFixed(2)}
                                reservationFee={reservationFee}
                                checkinDate={moment(checkinFromURL)}
                                deadlineDay={rate?.deadlineDay}
                                nonRefundable={rate?.nonRefundable}
                                policyRefundText={formatCancellationPolicy(rate, checkinFromURL)}
                              />
                            )}
                            trigger="click"
                          >
                            <InfoIcon className={infoIcon} />
                          </Popover>
                        </div>
                      </div>
                    </div>
                    {rate?.offer || rate?.promotion ? (
                      <span className={priceDiscounted}>
                        <FormattedNumber value={rate?.totalPrice} style="currency" currency="EUR" />
                      </span>
                    ) : (
                      <span className={price}>
                        <FormattedNumber value={rate?.totalPrice} style="currency" currency="EUR" />
                      </span>
                    )}
                  </div>
                </div>
              ))}
            {selectedRateDetails.length === 0 || !selectedRateDetails.some((rate) => rate) ? <p className={notRateAvailable}>{intl.formatMessage({ id: 'RatesSelector.NoPlanFound' })}</p> : ''}
          </div>
        </div>
      )} */}
    </>
  );
};

RatesSelector.propTypes = {
  filteredRatePlans: PropTypes.shape().isRequired,
  setSelectedRate: PropTypes.func,
  propertyID: PropTypes.string,
  setIsFirstLoad: PropTypes.bool.isRequired,
};

RatesSelector.defaultProps = {
  setSelectedRate: [],
  propertyID: "",
};
export default RatesSelector;
