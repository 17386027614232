import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import {
  paymentResume, totalResume, ivaFee, payNowAmount, payLaterAmount, agencyModeText,
} from './index.module.scss';

function PaymentResume({
  totalPrice, reservationFee, agencyMode,
}) {
  const intl = useIntl();
  const feePrice = totalPrice * reservationFee;
  const amountLeft = totalPrice * (1 - reservationFee);
  const ivaPercentage = 0.10;

  return (
    <div className={paymentResume}>
      <span className={totalResume}>
        <p>{intl.formatMessage({ id: 'Payment.Resume.Total' })}</p>
        <p>{intl.formatNumber(totalPrice, { style: 'currency', currency: 'EUR' })}</p>
      </span>
      <p className={ivaFee}>
        {agencyMode
          ? intl.formatMessage({ id: 'Payment.Resume.AgencyMode.IVA' })
          : intl.formatMessage({ id: 'Payment.Resume.IVA' }, { ivaPercentage })}

      </p>

      <span className={payNowAmount}>
        <p>{intl.formatMessage({ id: 'Payment.Resume.PayNow.Fee' }, { reservationFee })}</p>
        <p>{intl.formatNumber(feePrice, { style: 'currency', currency: 'EUR' })}</p>
      </span>

      {amountLeft > 0 && (
        <span className={payLaterAmount}>
          {!agencyMode ? (
            <>
              <p>
                {intl.formatMessage(
                  {
                    id: 'Payment.Resume.PayLater.Camping.Message',
                  },
                )}
              </p>
              <p>{intl.formatNumber(amountLeft, { style: 'currency', currency: 'EUR' })}</p>
            </>
          ) : (
            <p className={agencyModeText}>
              {intl.formatMessage({ id: 'Payment.Resume.PayNow.AgencyMode.Message' }, { amountLeft })}
            </p>
          )}
        </span>
      )}
    </div>
  );
}

PaymentResume.propTypes = {
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  reservationFee: PropTypes.number.isRequired,
  agencyMode: PropTypes.bool.isRequired,
};

export default PaymentResume;
